export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Services</h2>
          <p>
            Join Vidcor's marketplace of over 20,000 real estate investors who’ve bought and sold more than 500 properties.
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-3'>
                  {' '}
                  <i className={d.icon}></i>
                  <div className='service-desc'>
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                    {/* Adding the button here */}
                    <a
                      href='' // Update this href to link to the service's details or any other URL
                      className='btn btn-custom'
                      onClick={(e) => {
                        e.preventDefault(); 
                        props.onServiceButtonClick && props.onServiceButtonClick(d.name);
                      }}
                    >
                      {d.buttonText}
                    </a>{' '}
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  );
};

import { useState } from 'react'

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  message: '',
}

export const Contact = (props) => {
  const [{ firstName, lastName, email, phone, message }, setState] = useState(initialState);
  const { currentService } = props;
  
  const handlePhoneChange = (e) => {
    const { name, value } = e.target;

    if (name === 'phone') {
        let onlyNums = value.replace(/[^\d]/g, '');

        if (value.length < phone.length) {  // Check if the user is deleting
            if (onlyNums.length <= 1) {
                e.target.value = onlyNums;
            } else if (onlyNums.length <= 4) {
                e.target.value = '(' + onlyNums.slice(0, 3) + onlyNums.slice(3);
            } else if (onlyNums.length <= 7) {
                e.target.value = '(' + onlyNums.slice(0, 3) + ') ' + onlyNums.slice(3, 6) + onlyNums.slice(6, 10);
            }
        } else {  // Format the input as the user types
            if (onlyNums.length <= 3) {
                e.target.value = '(' + onlyNums;
            } else if (onlyNums.length <= 6) {
                e.target.value = '(' + onlyNums.slice(0, 3) + ') ' + onlyNums.slice(3);
            } else {
                e.target.value = '(' + onlyNums.slice(0, 3) + ') ' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6, 10);
            }
        }
    }

    setState((prevState) => ({ ...prevState, [name]: value }));
} 
  

  const getTitleAndDescription = () => {
    switch (currentService) {
      case "Sell Your Listing With Confidence":
        return { title: "Sell Your Listing", description: "Reach out today. Get a cash offer tomorrow. Close the Deal" };
      case "Sell Your Home Immediately":
        return { title: "Sell Your Home", description: "Reach out today. Get a cash offer tomorrow. Sell your home as-is and move on." };
      case "Sell an Investment Property":
        return { title: "Sell Your Property", description: "Reach out today. Get a cash offer tomorrow. Move on from the headache." };
      default:
        return { title: "Get Access", description: "Submit your information and receive access curated inventory of investor friendly properties." };
    }
  };

  const { title, description } = getTitleAndDescription();

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const clearState = () => setState({ ...initialState })

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        message: message,
        phone: phone,
        currentService: currentService,
        address: document.getElementById('address') ? document.getElementById('address').value : null
    };

    const url = '/.netlify/functions/sendEmail';

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
        });

        if (response.ok) {
            const result = await response.json();
            console.log('Email sent:', result.message);
            clearState();
            props.onFormSubmit();  // Call this only on success
        } else {
            const errorResult = await response.json();
            console.error('Error sending email:', errorResult.message);
        }
    } catch (error) {
        console.error('There was an error sending the email:', error);
    }
};

  return (
    <div>
       <div id='contact' style={{ position: 'relative' }}>
            <img src='/img/cityscape-night-compressed.png' alt='street-grid' className='kenburns' />
            <div className='overlay'>
                <div className='container'>
                    <div className='col-md-8'>
                        <div className='row'>
                            <div className='section-title'>
                            <h2>{title}</h2>
                            <p>{description}</p>
                          </div>
                          <form name='sentMessage' validate onSubmit={handleSubmit}>
                            <div className='row'>
                              <div className='col-md-6'>
                                <div className='form-group'>
                                  <input
                                    type='text'
                                    id='firstName'
                                    name='firstName'
                                    className='form-control'
                                    placeholder='First Name'
                                    required
                                    onChange={handleChange}
                                  />
                                  <p className='help-block text-danger'></p>
                                </div>
                              </div>
                              <div className='col-md-6'>
                                <div className='form-group'>
                                  <input
                                    type='text'
                                    id='lastName'
                                    name='lastName'
                                    className='form-control'
                                    placeholder='Last Name'
                                    required
                                    onChange={handleChange}
                                  />
                                  <p className='help-block text-danger'></p>
                                </div>
                              </div>
                              <div className='col-md-6'>
                                <div className='form-group'>
                                  <input
                                    type='text'
                                    id='phone'
                                    name='phone'
                                    className='form-control'
                                    placeholder='Phone Number'
                                    required
                                    onChange={handlePhoneChange}
                                  />
                                  <p className='help-block text-danger'></p>
                                </div>
                              </div>
                              <div className='col-md-6'>
                                <div className='form-group'>
                                  <input
                                    type='email'
                                    id='email'
                                    name='email'
                                    className='form-control'
                                    placeholder='Email'
                                    required
                                    onChange={handleChange}
                                  />
                                  <p className='help-block text-danger'></p>
                                </div>
                              </div>
                              {["Sell Your Listing With Confidence", "Sell Your Home Immediately", "Sell an Investment Property"].includes(currentService) && (
                                <div className='col-md-12'>
                                  <div className='form-group'>
                                    <input
                                      type='text'
                                      id='address'
                                      name='address'
                                      className='form-control'
                                      placeholder='Address'
                                      required
                                      onChange={handleChange}
                                    />
                                    <p className='help-block text-danger'></p>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className='form-group'>
                              <textarea
                                name='message'
                                id='message'
                                className='form-control'
                                rows='4'
                                placeholder='Message'
                                onChange={handleChange}
                              ></textarea>
                              <p className='help-block text-danger'></p>
                            </div>
                            <div id='success'></div>
                            <button type='submit' className='btn btn-custom btn-lg'>
                              Submit
                            </button>
                          </form>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
  )
}

export const Thankyou = (props) => {
    return (
      <div id='services' className='text-center thankyou-section'>
        <div className='container'>
          <div className='section-title'>
            <h2>Thank You!</h2>
            <p>
              A Vidcor Specialist will contact you shortly to set up an onboarding call and get you access to our exclusive marketplace.
            </p>
          </div>
          <div className='row'>
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className='col-md-3'>
                    {' '}
                    <i className={d.icon}></i>
                    <div className='service-desc'>
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                      {/* Adding the button here */}
                      <a
                        href='' // Update this href to link to the service's details or any other URL
                        className='btn btn-custom'
                        onClick={(e) => {
                          e.preventDefault(); 
                          props.onServiceButtonClick && props.onServiceButtonClick(d.name);
                        }}
                      >
                        {d.buttonText}
                      </a>{' '}
                    </div>
                  </div>
                ))
              : 'loading'}
          </div>
        </div>
      </div>
    );
  };
  
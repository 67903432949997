import React from 'react';
import JsonData from '../data/data.json';
import { Link, useParams } from 'react-router-dom';

const allPostsData = [
    {
        imgSrc: "/img/house-cover.png",
        title: "The Pain of Repairing a Distressed Home 1",
        description: "Let's take a deep dive into the actual costs of repairing a home versus selling the home in an as-is condition.",
        link: "#",
        author: "John Doe",
        date: "11.24.23"
    },
    {
        imgSrc: "/img/house-cover.png",
        title: "The Pain of Repairing a Distressed Home 2",
        description: "Let's take a deep dive into the actual costs of repairing a home versus selling the home in an as-is condition.",
        link: "#",
        author: "Jane Smith",
        date: "11.25.23"
    },
    // ... add more blog posts as needed
];

export const BlogPostDetail = (props) => {
    const { postName } = useParams();
    const postDetails = allPostsData.find(post => post.title.split(" ").join("-").toLowerCase() === postName);

    // If no post matches the postName, you can render a "Not Found" message or similar
    if (!postDetails) {
        return <div>Post not found.</div>;
    }

    // Base URL of your site - replace with your actual domain
    const baseUrl = 'https://www.yoursite.com';

    // Construct the full URL of the current post
    const currentPostUrl = `${baseUrl}/blog/${postName}`;

    // Encode the URL and title for use in sharing links
    const encodedPostUrl = encodeURIComponent(currentPostUrl);
    const postTitle = encodeURIComponent(postDetails.title);

    return (
        <div id="blog-post-detail">
            <div className="blog-post-detail-header">
                <img src={postDetails.imgSrc} alt='Blog' className='kenburns' />

                {/* Breadcrumb Navigation */}
                <div className="breadcrumb">
                    <Link to="/">Home</Link> &rarr;{' '}
                    <Link to="/blog">Blog</Link> &rarr;{' '}
                    <span>{"Current Blog"}</span>
                </div>

                <p>{postDetails.date}</p>
                <h1>{postDetails.title}</h1>
                <p>By {postDetails.author}</p>

                {/* Social Media Sharing Icons */}
                <div className="social-icons">
                    {JsonData.BlogPost.map((d, i) => (
                        <a
                            key={i}
                            href={d.shareUrl
                                .replace('PLACEHOLDER_URL', encodedPostUrl)
                                .replace('PLACEHOLDER_TITLE', postTitle)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i className={d.icon}></i>
                        </a>
                    ))}
                </div>
            </div>

            {/* Blog Post Content */}
            <div className="blog-post-container">
                <div className="blog-post-content">
                    <h2>Subheader 1</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.
                    </p>
                    <img src="/img/house-cover.png" alt="Sample Description" className="blog-content" />
                    <h2>Subheader 2</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur in nulla ut magna vehicula libero luctus in ipsum consequat faucibus.</p>
                    <blockquote>
                        A meaningful quote from the post or someone relevant.
                    </blockquote>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum.</p>
                </div>
            </div>
        </div>
    );
};

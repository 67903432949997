import { useState, useEffect } from 'react';
import { Navigation } from './components/navigation';
import { Header } from './components/header';
import { Contact } from './components/contact';
import { About } from './components/about';
import { Services } from './components/services';
import { Gallery } from './components/gallery';
import { Thankyou } from './components/thankyou';
import { Testimonials } from './components/testimonials';
import { Blog } from './components/blog';
import { BlogPostDetail } from './components/blog-post-detail';
import { Footer } from './components/footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import JsonData from './data/data.json';
import SmoothScroll from 'smooth-scroll';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [showContact, setShowContact] = useState(false);
  const [overlayStatus, setOverlayStatus] = useState('none'); // possible values: 'none', 'entering', 'entered', 'exiting'
  const [currentService, setCurrentService] = useState(null);
  const [showThankyou, setShowThankyou] = useState(false);
  const [showHeader, setShowHeader] = useState(true);

  useEffect(() => {
    setLandingPageData(JsonData);

    // Check for touch support and add class to body
    if ('ontouchstart' in window) {
      document.body.classList.add('mobile-device');
    }

  }, []);

  const handleGetStartedClick = () => {
    setCurrentService('Get Started');
    setOverlayStatus('entering');
    setTimeout(() => {
      setOverlayStatus('entered');
      setTimeout(() => {
        setShowContact(true);
        setOverlayStatus('exiting');
        setTimeout(() => {
          setOverlayStatus('none');
        }, 500);
      }, 500);
    }, 500);
  };

  const handleServicesClick = (serviceIdentifier) => {
    setOverlayStatus('entering');
    setTimeout(() => {
      setOverlayStatus('entered');
      setTimeout(() => {
        setShowContact(true);
        setOverlayStatus('exiting');
        setTimeout(() => {
          setOverlayStatus('none');
        }, 100);
      }, 100);
    }, 100);
    setCurrentService(serviceIdentifier);
    scroll.animateScroll(0);
  };
  
  const handleFormSubmit = () => {
    setOverlayStatus('entering');
    setShowHeader(false);  // Hide header immediately
    setTimeout(() => {
      setOverlayStatus('entered');
      setTimeout(() => {
        setShowThankyou(true);
        setShowContact(false);  // Hide the Contact component
        setOverlayStatus('exiting');
        setTimeout(() => {
          setOverlayStatus('none');
        }, 500);
      }, 500);
    }, 500);
  };
  
  

  return (
    <Router>
      <Navigation onBuyClick={handleServicesClick} onSellClick={() => handleServicesClick('Sell Your Home Immediately')} />
    
      <Routes>
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:postName" element={<BlogPostDetail />} />
        <Route path="/" element={
          <>
            {showThankyou ? 
              <Thankyou data={landingPageData.Thankyou} />
            : showContact ? 
              <Contact data={landingPageData.Contact} currentService={currentService} onFormSubmit={handleFormSubmit} />
            : showHeader && 
              <Header data={landingPageData.Header} onGetStartedClick={handleGetStartedClick} currentService={currentService} />
            }
            {overlayStatus !== 'none' && <div className={`black-overlay ${'black-overlay-' + overlayStatus}`}></div>}
            {!showThankyou && <Services data={landingPageData.Services} onServiceButtonClick={handleServicesClick} />}
            <About data={landingPageData.About} />
            <Gallery data={landingPageData.HowItWorks} />
            <Testimonials data={landingPageData.Testimonials} />
          </>
        } />
      </Routes>
    
      <Footer onBuyClick={handleServicesClick} onSellClick={() => handleServicesClick('Sell Your Home Immediately')} />
    </Router>
  )
  
}


export default App;


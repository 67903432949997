export const Gallery = (props) => {
  return (
    <div id='gallery'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12'>
            <div className='gallery-header'>
              <h2>How It Works</h2>
              <p>{props.data ? "Here's the step-by-step process with Vidcor." : 'loading...'}</p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-md-10 col-lg-8'>
            <div className='gallery-text'>
              <div className='list-style'>
                <div className='col-lg-12 col-sm-12 col-xs-12'>
                  <ul>
                    {props.data && props.data.map((step, index) => (
                      <li key={index}>
                        <h3>{step.title}</h3>
                        <p>{step.text}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export const Blog = () => {
    const allPostsData = [
        // You can add more post objects here as needed
        // Example:
        {
            imgSrc: "/img/house-cover.png",
            title: "The Pain of Repairing a Distressed Home 1",
            description: "Let's take a deep dive into the actual costs of repairing a home versus selling the home in an as-is condition.",
            link: "#"
        },
        {
            imgSrc: "/img/house-cover.png",
            title: "The Pain of Repairing a Distressed Home 2",
            description: "Let's take a deep dive into the actual costs of repairing a home versus selling the home in an as-is condition.",
            link: "#"
        },
        {
            imgSrc: "/img/house-cover.png",
            title: "The Pain of Repairing a Distressed Home 3",
            description: "Let's take a deep dive into the actual costs of repairing a home versus selling the home in an as-is condition.",
            link: "#"
        },
        {
            imgSrc: "/img/house-cover.png",
            title: "The Pain of Repairing a Distressed Home 4",
            description: "Let's take a deep dive into the actual costs of repairing a home versus selling the home in an as-is condition.",
            link: "#"
        },
        {
            imgSrc: "/img/house-cover.png",
            title: "The Pain of Repairing a Distressed Home 5",
            description: "Let's take a deep dive into the actual costs of repairing a home versus selling the home in an as-is condition.",
            link: "#"
        },
        {
            imgSrc: "/img/house-cover.png",
            title: "The Pain of Repairing a Distressed Home 6",
            description: "Let's take a deep dive into the actual costs of repairing a home versus selling the home in an as-is condition.",
            link: "#"
        },
        {
            imgSrc: "/img/house-cover.png",
            title: "The Pain of Repairing a Distressed Home 7",
            description: "Let's take a deep dive into the actual costs of repairing a home versus selling the home in an as-is condition.",
            link: "#"
        }
    ];

    // Initial state for the number of posts to display
    const [numPostsToShow, setNumPostsToShow] = useState(6);
    const displayedPosts = allPostsData.slice(0, numPostsToShow);

    const handleSeeMore = () => {
        // Show the next 6 posts
        setNumPostsToShow(prev => prev + 6);
    };

    return (
        <div id="blog">
            <div className="blog-header">
                <img src='/img/blog-newspaper-compressed.png' alt='street-grid' className='kenburns' />
                <h1>Knowledge is the most valuable investment.</h1>
                <p>Stay updated with our expert insights, latest trends, and invaluable tips for unmatched success.</p>
            </div>
            <div className="container">
                {displayedPosts.map((post, index) => (
                    <Link to={`/blog/${post.title.split(" ").join("-").toLowerCase()}`} key={index} className="blog-post">
                        <img src={post.imgSrc} alt="Blog Thumbnail" />
                        <div className="blog-post-content">
                            <h2>{post.title}</h2>
                            <p>{post.description}</p>
                            <span className="continue-reading">Continue reading &rarr;</span>
                        </div>
                    </Link>
                ))}
                {numPostsToShow < allPostsData.length && (
                    <button onClick={handleSeeMore} className="btn-custom" style={{ margin: '20px auto', display: 'block' }}>
                        See More
                    </button>
                )}
            </div>
        </div>
    );
};

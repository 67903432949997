
export const About = (props) => {
  
  return (
    <div id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12'>
            <div className='about-header'>
              <h2>Our Focus</h2>
              <p>{props.data ? props.data.paragraph : 'loading...'}</p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>

            {/* Here is the 3D effect replacing your image */}
            <div className="example">
              <div className="block">
                  <div className="side -main" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/street-grid-gold.png)` }}>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </div>
                  <div className="side -left" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/street-grid-gold.png)` }}></div>
              </div>
           </div>

          </div>
          <div className='col-xs-12 col-md-6'>
            <div className='about-text'>
              <div className='list-style'>
                <div className='col-lg-12 col-sm-12 col-xs-12'>
                  <ul>
                    <li>
                      <h3>Vast Property Selection</h3>
                      <p>{props.data ? props.data.expandedProperty : 'loading...'}</p>
                    </li>
                    <li>
                      <h3>Streamlined Experiences</h3>
                      <p>{props.data ? props.data.streamlinedExperiences : 'loading...'}</p>
                    </li>
                    <li>
                      <h3>Expert Representation</h3>
                      <p>{props.data ? props.data.localRepresentation : 'loading...'}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import ParticlesBg from "particles-bg";

const getParticleCount = () => {
  const width = window.innerWidth;

  if (width <= 480) {       // For mobile screens
    return 25;
  } else if (width <= 768) {  // For tablets
    return 50;
  } else if (width <= 1920) {  // For typical desktop screens
    return 75;
  } else {                   // For large 4K screens
    return 100;
  }
};
export const Footer = ({ onBuyClick, onSellClick}) => {
  return (
    <div>
      <div id='footer'>
        <ParticlesBg type="cobweb" color={["#BB9A65"]} num={getParticleCount()} bg={{ zIndex: 0, position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }} />
        
        <div className='overlay'>
          <div className='container text-center'>

            <nav id='footer-menu' className='navbar navbar-default'>
              <div className='navbar-inner'>
                <ul className='nav navbar-nav navbar-center'>
                  <li>
                    <a href='' className='page-scroll' onClick={(e) => {e.preventDefault(); onBuyClick();}}>
                      Buy
                    </a>
                  </li>
                  <li>
                    <a href='' className='page-scroll' onClick={(e) => {e.preventDefault(); onSellClick();}}>
                      Sell
                    </a>
                  </li>
                  <li>
                    <a href='#services' className='page-scroll'>
                      Services
                    </a>
                  </li>
                  <li>
                    <a href='#testimonials' className='page-scroll'>
                      Reviews
                    </a>
                  </li>
                </ul>
              </div>
            </nav>

            <p className='copyright'>
              &copy; <a href="#" className='footer-link'>Vidcor</a> 2023, All Rights Reserved.
            </p>
            <p className='disclaimer'>
              When you visit or interact with our sites, services or tools, we or our authorised service providers may use cookies for storing information to help provide you with a better, faster and safer experience and for marketing purposes.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

